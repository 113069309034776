import type { NextPage } from "next";
import { useRouter } from "next/router";
import { useEffect } from "react";

const NotFoundPage: NextPage = () => {
  const router = useRouter();
  const { asPath } = router;
  const locale = asPath.startsWith("/ca") ? "ca" : "es";

  useEffect(() => {
    if (locale === "es") router.push("404-pagina");
    else router.push("ca/404-pagina");
  }, []);

  return <></>;
};

export default NotFoundPage;
